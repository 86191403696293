<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Distributor Curah"
    allow-clear
    show-search
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @dropdownVisibleChange="() => (state.params.q = '')"
    v-bind="$attrs"
    :loading="state.loading"
    :show-arrow="true">
    <a-select-option v-for="d in state.data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const state = reactive({
      endpoint: '/api/filter/distributor-curah-all',
      loading: false,
      data: [],
      params: {
        q: '',
      },
    })

    const fetchLists = () => {
      state.loading = true
      apiClient
        .get(state.endpoint, { params: state.params })
        .then(({ data }) => {
          state.data = data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .finally(() => {
          state.loading = false
        })
    }

    const onSearch = (value) => {
      state.params.q = value
    }

    const highlight = value => {
      return value.replace(new RegExp(state.params.q, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    onMounted(() => {
      fetchLists()
    })

    watch(state.params, debounce(() => {
      fetchLists()
    }, 300))

    return {
      state,
      valueState: useVModel(props, 'value'),
      highlight,
      onSearch,
    }
  },
}
</script>

<style></style>
