<template>
    <AModal
        :visible="visible"
        title="Details"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">
        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            size="small"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        </MdTable>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'

export default defineComponent({
    props: {
        visible: [Boolean],
        items: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            data: [],
            columns: [
                {
                    title: 'Kode Produk',
                    dataIndex: 'material_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'material_name',
                },
                {
                    title: 'Tonase',
                    dataIndex: 'tonase',
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:items', [])
        }

        // handle vue
        onMounted(() => {
            state.data = props.items
        })

        return {
            handleModalCancel,
            state,
        }
    },
})
</script>